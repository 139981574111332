// Css raiz do projeto

/*!
 * Clube de Vantagens V03
 * Desenvolvido por Fattoria em: 15/05/2023
 */

// Config
@import "../sass/config/_variaveis.scss";
@import "../sass/config/_medias.scss";

// Tipografia
@import "../sass/_tipografia.scss";

// Reset
@import "../sass/padrao/geral/_reset.scss";

// Componentes
@import "../sass/padrao/componentes/_btns.scss";
@import "../sass/padrao/componentes/_cards.scss";
@import "../sass/padrao/componentes/_filtros.scss";
@import "../sass/padrao/componentes/_header.scss";
@import "../sass/padrao/componentes/_dropdowns.scss";
@import "../sass/padrao/componentes/_banners.scss";
@import "../sass/padrao/componentes/_carouseis.scss";
@import "../sass/padrao/componentes/_footer.scss";
@import "../sass/padrao/componentes/_forms.scss";
@import "../sass/padrao/componentes/_collapse.scss";
@import "../sass/padrao/componentes/_modal.scss";
@import "../sass/padrao/componentes/_tables.scss";
@import "../sass/padrao/componentes/_accordion.scss";
@import "../sass/padrao/componentes/_loaders.scss";
@import "../sass/padrao/componentes/_alerts.scss";
@import "../sass/padrao/componentes/_tooltips.scss";

// Páginas
@import "../sass/padrao/paginas/_voucher.scss";

// Geral
@import "../sass/padrao/geral/_geral.scss";




























































































