.categoria-aside_collapse {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;    
    padding: 0 10px 16px;
    border-bottom: 1px solid #D5D6D7;

    .filtro_title {
        margin-bottom: 0 !important;
    }

    .icones {
        font-size: 8px;
        color: #0061AA;
        transition: 0.4s ease;
    }  
    
    &[aria-expanded='true'] {
        .icones {
            transform: rotateZ(-180deg);
        }
    }
}
