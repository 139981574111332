.home-banner_carousel {
    position: relative;
    .swiper-wrapper {
        align-items: stretch;

        .swiper-slide {
            height: auto;
        }
    }
    .swiper-pagination {
        width: initial !important;       
        bottom: 24px !important;       

        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            background-color: white;
            opacity: 0.4;

            &-active {
                opacity: 1;
            }
            
        }
    }  
    
    .banner-carousel-btns_wrapper {
        display: none;
        position: absolute;
        z-index: 2;     
        top: 50%;
        transform: translateY(-50%);

        @media(min-width: 992px) {
            display: block;
            left: 32px;
        }

        @media(min-width: 1200px) {
            display: block;
            left: 56px;
        }

        .btn-handler-banner-carousel {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            min-width: 48px;
            height: 48px;
            border-radius: 50%;
            border: 3px solid #F0F0F0;
            background-color: white;
            opacity: 0.5;
            outline: none;
            margin-bottom: 16px;
            transition: 0.4s ease;   
            padding: 0;  
            overflow: hidden;    

            &:last-child {
                margin-bottom: 0;
            }

            &.active {
                opacity: 1;
                border-color: #FFF;
            }

            &:hover {
                opacity: 1;
            }
        }
    }
}

.campanha-cards_carousel {    

    @media(max-width: 767px) {
        overflow: visible !important;
    }

    .swiper-wrapper {        
        @media(min-width: 768px) {
            flex-wrap: wrap;
            margin: 0 -8px;
        }
        .swiper-slide {  
            width: 310px;    

            @media(min-width: 768px) {
                width: calc(33.33% - 16px);
                margin: 0 8px 16px;
            }

            @media(min-width: 1200px) {
                width: calc(25% - 16px);         
            }
        }
    }
}

.novo-banner {
    @media (min-width: 992px) {
        height: 552px;
    }

    .swiper-pagination {
        left: 50% !important;
        transform: translateX(-50%);

        @media (min-width: 992px) {
            width: max-content !important;
            bottom: 0 !important;
            left: 0 !important;
            top: 0 !important;
            right: 0;
            transform: translateX(0);
            position: relative;
            display: flex;
            align-items: center;
        }

        .swiper-pagination-bullet {
            @media (min-width: 992px) {
                width: 176px;
                height: 5px;
                background-color: rgba(255, 255, 255, .25);
                border-radius: 6px;
                position: relative;
                opacity: 1;
            }

            &:hover {
                .swiper-pagination-text {
                    @media (min-width: 992px) {
                        opacity: 1;
                    }
                }
            }
        }
    
        .swiper-pagination-bullet-active {
            @media (min-width: 992px) {
                --progress: 0;
                width: 176px;
                height: 5px;
                border-radius: 6px;
                background-color: rgba(255, 255, 255, .25);
                transition: ease;
            }

            &::after{
                @media (min-width: 992px) {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width:  var(--progress);
                    height: 5px;
                    background-color: #FFF;
                    border-radius: 6px;
                }
            }
        }

        .swiper-pagination-text {
            display: none;
            @media (min-width: 992px) {
                position: absolute;
                top: -11px; 
                left: 50%;
                transform: translateX(-50%);
                opacity: 0;
                transition: opacity 0.3s ease;
                color: #EBEBEB;
                text-align: center;
                font-size: 12px;
                font-weight: 400;
                line-height: normal;
                width: 129px;
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: center;
            }
        }
    }

    .swiper-button-next {
        display: none;

        @media (min-width: 992px) {
            bottom: calc(50% - 20px);
            right: 40px;
            background-color: #FFF;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: #991624;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            transition: all ease-in-out .4s;
            opacity: .6;
        }
        
        @media (min-width: 1200px) {
            right: 56px;
        }

        &::after {
            content: '' !important;
        }

        &:hover {
            opacity: 1;
        }
    }

    .swiper-button-prev {
        display: none;

        @media (min-width: 992px) {
            left: 40px;
            bottom: calc(50% - 20px);
            background-color: #FFF;
            border-radius: 50%;
            color: #991624;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            transition: all ease-in-out .4s;
            opacity: .6;
            transform: rotate(180deg);
        }

        @media (min-width: 1200px) {
            left: 56px;
        }

        &::after {
            content: '' !important;
        }

        &:hover {
            opacity: 1;
        }
    }

    .icone-play-pause {
        z-index: 100;
        color: white;
        display: none;
        margin-left: 20px;

        @media (min-width: 992px) {
            display: block;
        }

        .play {
            cursor: pointer;
            display: none;
        }

        .pause {
            cursor: pointer;
        }
    }

    .pagination-banner {
        display: flex;
        position: absolute;
        bottom: 27px;
        width: 100%;
        justify-content: center;
    }
}