/* ==========================================================================
   Voucher
   ========================================================================== */

   .voucher-impressao {
    .voucher{
        border: 3px solid #e1e1e1;
        padding: 30px;
        font-size: 14px;

        b{ color: #000; }
        
        h3{ font-size: 18px; }
    
        .cpf-cliente{
            font-family: 'bradescosans-bold';
        }
        &-logo{
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 30px;
            display: block;
          
            @media(min-width: 768px) {
                margin-left: 0;
                margin-right: 0;
            }
        }
        &-header{
            h3,p{
                text-align: left;        
    
                @media(min-width: 768px) {
                    text-align: right;
                }  
            }
    
            h3{ margin-top: 0; margin-bottom: 3px; }
    
            p{ margin: 0; font-size: 12px;}
    
            @media(min-width: 768px) {
                margin-bottom: 20px;
            }       
    
        }
        ul{ list-style: initial; margin: initial; padding-left: 20px;}
    }
    
    .termos-gerais{
        padding: 10px 10px;
        font-size: 11px;
        h5{ font-size: 16px; }
    }
    
    .cut-line{
        margin-top: 20px;
        border-bottom: 1px dashed #e1e1e1;
        display: block;
        position: relative;
        &:after{
            content: "\2702";
            position: absolute;
            left: 20%; top: 0;
            color: #e1e1e1;
            font-size: 20px;
            line-height: 1px;
        }
    }  
    
    .codigo-wrapper{
        padding: 15px 45px 15px 30px;
        background-color: #F4F4F4;
        
        p{
            font-size: 18px;
            color: #000;
            span{ font-size: 12px; }
        }
        .codigo{
            margin-bottom: 0;
            font-size: 56px;
            color: #666;
        }
    }
    
    .info{
        p{
            margin-bottom: 0;
            font-size: 14px;
            color: #000;
        }
        .subtitulo{
            font-size: 18px;
            font-family: 'bradescosans-bold';
            margin-top: 0;
        }
    }
    
    .detalhe-voucher{   
    
        @media(min-width: 768px) {
            padding: 0 90px 0 90px;
        } 
    
        .voucher{
            padding: 20px 0 0 0;
            border: none;
    
            @media(min-width: 768px) {
                padding: 75px 0 0 0;
            }        
        }
    
        .codigo-wrapper,
        .info{
            display: block;   
    
            @media(min-width: 768px) {
                display: inline-block; 
                vertical-align: middle;
            } 
        }
    
        .codigo-wrapper{
            padding: 0;
            background-color: transparent;
            border-right: none;
            margin-left: -15px;
            margin-right: -15px;
            margin-top: 30px;    
          
            @media(min-width: 768px) {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            } 
    
            @media(min-width: 992px) {
                float: left;
                vertical-align: top;
                padding: 0;
                width: 100%;
                max-width: 500px;
                background-color: transparent;
                border-right: 2px solid #BEBEBE;
            } 
    
            @media(min-width: 1200px) {
                max-width: 555px;
            }        
        }
    
        .info{
            width: 100%;
            padding-left: 0;     
    
            @media(min-width: 992px) {
                float: right;
                vertical-align: top;
                padding-left: 50px;
                margin-top: 30px;
                max-width: calc(100% - 500px);
            } 
            
            @media(min-width: 1200px) {
                max-width: calc(100% - 555px);
            }       
        }
    
        .codigo-bg{
            padding: 30px;
            border-right: 5px solid #BEBEBE;
            background-color: #F4F4F4;
    
            @media(min-width: 768px) {
                padding: 20px 60px;
                border-right: none;
            }
    
            @media(min-width: 992px) {
                padding: 20px 50px;
            }    
          
        }
    
        .codigo{
            @media(max-width: 767px) {
                font-size: 42px;
            }   
        }
    
        .subtitulo{
            i{ font-size: 35px; }
            span,
            i{ display: inline-block; vertical-align: middle }
            span{ margin-left: 5px; }
        }
    
        .conteudo{
            margin-top: 30px;
            padding-bottom: 50px;
            border-bottom: 1px solid #DDDDDD;
            font-size: 16px;
    
            @media(min-width: 768px) {
                margin-top: 50px;
            }  
            
            b{ color: #000; }
        }
    
        .termos-gerais{
            padding: 15px 0;
    
            @media(min-width: 768px) {
                padding: 35px 0;
            }      
        }
    
        .btn-vermelho{
            @media(max-width: 767px) {
                margin-bottom: 20px;
            }    
        }
    }
   }


