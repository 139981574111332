.alert_box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 16px;
    border-radius: 5px;
    border: 1px solid transparent;
    margin-bottom: 16px;

    .icon {
        margin-right: 8px;
    }

    .alert_text {
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }

    &.error_alert {
        background-color: #FBEAED;
        border-color: #CC092F;

        .alert_text {
            color: #CC092F;
        }
    }

    &.success_alert {
        background-color: #ECF9EC;
        border-color: #B4E6B3;

        .alert_text {
            color: #026600;
        }
    }
}