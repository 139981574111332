.header {
    position: relative;
    width: 100%;

    .header_red-bar {
        position: relative;
        z-index: 5;
        padding: 10px 0 10px;
        background-color: #CC092F;
        color: white;

        @media(min-width: 1200px) {
            padding: 8px 0 9px;
            justify-content: space-between;
        }

        &.mediservice {
            background-color: #003866;
        }
        &.mediservice-novo {
            background-color: #006CA9;
        }

        .container {
            display: flex;
            align-items: center;
            justify-content: center;

            @media(min-width: 1200px) {
                justify-content: space-between;
            }

            .ofertas-regioes_text {
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                margin-bottom: 0;

                .regioes_link {
                    display: inline-flex;
                    align-items: center;
                    color: white;
                    transition: 0.4s ease;

                    &:hover {
                        opacity: 0.8;
                    }

                }
            }

            .red-bar_links-list {
                display: none;

                @media(min-width: 1200px) {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;
                }

                .red-bar_links_item {
                    margin-right: 24px;

                    &:last-child {
                        margin-right: 0;
                    }

                    .red-bar_link {
                        color: white;
                        text-decoration: none;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                        transition: 0.4s ease;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .header_main {
        overflow: hidden;
        position: relative;
        z-index: 5;
        background-color: white;

        @media(min-width: 992px) {
            overflow: initial;
        }

        .container {

            .top_content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px 0;

                @media(min-width: 1200px) {
                    padding: 19px 0 11px;
                }

                .header_logo-holder {
                    display: flex;
                    align-items: center;

                    .logo {
                        margin-right: 16px;
                    }

                    &-sincor{
                        @media(max-width: 768px){
                            gap: 8px;
                            
                        .logo-sincor-sp{
                                max-width: 60px;
                                margin-right: 14px;
                                align-self: center;
                            }
                        }
                    }
                }

                .search_form {
                    flex: 1;

                    @media(min-width: 992px) {
                        margin-left: 46px;
                    }

                    @media(min-width: 1200px) {
                        margin: 0 25px 0 40px;
                    }

                    &.search_form-desktop {
                        display: none;

                        @media(min-width: 992px) {
                            display: block;
                        }
                    }
                }

                .logo {
                    width: 100%;
                    max-width: 98px;

                    @media (min-width: 380px){
                        max-width: 126px;
                    }
                }

                .logo-mediservice {
                    max-width: 120px;

                    @media (min-width: 380px){
                        max-width: initial;
                    }
                }

                .logo-capi {
                    @media (min-width: 380px){
                        max-width: 150px;
                    }
                }

                .right-side {
                    display: flex;
                    align-items: center;

                    .btn-cadastrar {
                        display: none;

                        @media(min-width: 1200px) {
                            display: flex;
                        }
                    }

                    .btn-entrar-cadastrar {
                        padding: 12px 31px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        border-radius: 5px;
                        text-decoration: none;
                        transition: 0.4s ease;

                        @media(min-width: 1200px) {
                            padding: 16px 32px;
                        }
                    }

                    .btn-entrar {
                        border: 1.5px solid #D90037;
                        color: #D90037;
                        text-decoration: none;
                        margin-right: 24px;

                        @media(min-width: 1200px) {
                            margin-right: 0;
                            margin-left: 8px;
                            background-color: #CC092F;
                            color: white;

                            &:hover {
                                opacity: 0.8;
                            }
                        }


                        &.azul{
                            border: 1.5px solid #006CA9;
                            color: #006CA9;
                            font-size: 16px;

                            @media(min-width: 1200px){
                                background-color: #006CA9;
                                color: white;
                            }
                        }
                    }

                    .btn-cadastrar {
                        @media(min-width: 1200px) {
                            background-color: transparent;
                            color: #CC092F;

                            &:hover {
                                background-color: #F7F7F7;
                            }
                        }

                        &.azul{
                            @media(min-width: 1200px) {
                                font-size: 16px;
                                background-color: transparent;
                                color: #006CA9;
    
                                &:hover {
                                    background-color: #F7F7F7;
                                }
                            }
                        }
                    }

                    .user-info_btn {
                        display: none;
                        background-color: transparent;
                        border: 0;
                        outline: none;
                        color: black;
                        margin-right: 24px;
                        padding: 0;
                        max-width: 120px;
                        min-width: 120px;

                        @media(min-width: 992px) {
                            align-items: center;
                            justify-content: space-between;
                            margin-right: 0;
                            width: 267px;
                            max-width: initial;
                            background: #FFFFFF;
                            border-radius: 10px;
                            padding: 16px 24px;
                            transition: background-color 0.6s ease;
                            min-width: 267px;

                            &:hover {
                                background-color: #F7F7F7;
                            }
                        }

                        .user_content {
                            display: flex;
                            align-items: center;
                            color: #000000;
                            width: 100%;

                            .user_name {
                                display: block;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                                margin-left: 8px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                width: 100%;

                                @media(min-width: 992px) {
                                    width: 70%;
                                }
                            }

                            .icone-usuario {
                                font-size: 18px;
                            }
                        }

                        .icone-chevron-down {
                            display: none;

                            @media(min-width: 992px) {
                                display: block;
                                font-size: 8px;
                                transition: transform 0.4s ease;
                            }
                        }

                        &.show {
                            background-color: #CC092F;
                            color: white;
                            border-radius: 10px 10px 0 0;

                            .user_content {
                                color: white;
                            }

                            .icone-chevron-down {
                                transform: rotateZ(-180deg);
                            }
                        }

                        &.user-info_btn_azul{
                            
                            &.show{
                                background-color: #006CA9;
                            }
                        }
                    }

                    .menu_hamb-btn {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        background-color: transparent;
                        border: 0;
                        outline: none;
                        height: 14px;
                        padding: 0;

                        @media(min-width: 992px) {
                            display: none;
                        }

                        .bar {
                            display: block;
                            width: 27px;
                            height: 2px;
                            background-color: black;
                            border-radius: 4px;
                            transition: 0.4s ease-in-out;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .mobile-search_wrapper {
        @media(min-width: 992px) {
            display: none;
        }
    }

    .input-search_wrapper {
        position: relative;
        width: 100%;

        .icone-lupa {
            position: absolute;
            top: 50%;
            left: 16px;
            transform: translateY(-50%);
            color: #999999;
        }

        .input-search {
            padding: 0 24px 0 50px;
            height: 48px;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            width: 100%;
            border: 1px solid #DEDEDE;
            border-radius: 5px;
            outline: none;

            @media(min-width: 992px) {
                width: 80%;
            }

            @media(min-width: 1200px) {
                width: 100%;
            }

            &::-webkit-input-placeholder {
                color: #999999;
            }

            &:-moz-placeholder {
                color: #999999;
            }

            &::-moz-placeholder {
                color: #999999;
            }

            &:-ms-input-placeholder {
                color: #999999;
            }

            &:-webkit-autofill {
                -webkit-box-shadow: none !important;
            }

            &:-webkit-autofill {
                -webkit-text-fill-color: black !important;
            }
        }
    }

    .header-categories_wrapper {
        .categories_desktop {
            display: none;

            @media(min-width: 992px) {
                display: block;
                padding-bottom: 24px;
            }

            .container {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                @media(min-width: 992px) {
                    justify-content: space-around;
                }

                @media(min-width: 1200px) {
                    justify-content: flex-start;
                }

                .category-navegue_text {
                    font-size: 12px;
                    line-height: 15px;
                    color: #000000;
                    opacity: 0.7;
                }

                .ver-todas_btn {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #CC092F;
                    text-decoration: none;
                    transition: 0.4s ease;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .categories_mobile {

            @media(min-width: 992px) {
                display: none;
            }
        }

        .categories_list {
            display: flex;
            align-items: stretch;
            padding: 16px 12px;
            overflow: auto;
            margin-bottom: 0;

            @media(min-width: 1200px) {
                overflow: initial;
                padding: 0;
                margin: 0 50px 0 24px;
            }

            .category_item {
                min-width: 72px;
                width: 72px;
                margin-right: 8px;
                height: inherit;

                &:last-child {
                    margin-right: 0;
                }

                @media(min-width: 992px) {
                    min-width: initial;
                    width: initial;
                    margin-right: 32px;
                }

                .category_link {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    text-decoration: none;
                    color: #000000;
                    height: 100%;

                    @media(min-width: 992px) {
                        flex-direction: row;
                        text-align: initial;

                        &:hover {
                            .link_text {
                                color: #D90037;
                            }
                        }
                    }

                    .img-categories {
                        margin-right: 10px;
                    }

                    .link_text {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        white-space: nowrap;
                        font-size: 11px;
                        line-height: 13px;
                        flex: 1;

                        @media(min-width: 992px) {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 17px;
                            transition: 0.4s ease;
                        }
                    }
                }
            }

            &_azul{

                .category_item{
                    .category_link{

                        @media(min-width: 992px) {
    
                            &:hover {
                                .link_text {
                                    color: #006CA9;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .header_nav {
        position: fixed;
        z-index: 4;
        top: 106px;
        left: 0;
        right: 0;
        transform: translateY(-140%);
        //transition: 0.6s ease;
        height: calc(100vh - 74px - 31px);
        overflow: auto;

        @media(min-width: 992px) {
            position: absolute;
            top: calc(100% - 93px);
            height: calc(100vh - 97px - 49px);
            overflow: auto;
            transform: translateY(-100%);
        }

        @media(min-width: 1200px) {
            top: calc(100% - 59px);
        }


        .header-nav-links_wrapper {
            position: relative;
            background-color: #FAFAFA;
            padding: 24px 0;

            @media(min-width: 992px) {
                display: none;
            }

            .header-nav_links-list {
                width: 100%;
                margin-bottom: 0;

                .header-nav_link-item {
                    width: 100%;

                    .header-nav_link {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        text-decoration: none;
                        color: #000000;

                        .nav-link_text {
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 250%;
                        }
                    }
                }
            }
        }



        .header-nav-categories_wrapper {
            background-color: white;

            @media(min-width: 992px) {
                position: relative;
            }

            .flutuante {
                display: none;
                position: absolute;
                left: 50%;
                top: 37%;
                transform: translate(-50%, -50%);

                @media(min-width: 992px) {
                    display: block;
                }
            }

            .container {
                position: relative;
                padding-top: 44px;
                padding-bottom: 20px;

                @media(min-width: 992px) {
                    padding-top: 57px;
                    padding-bottom: 32px;
                }

                @media(min-width: 1600px) {
                    padding-bottom: 74px;
                }

                .btn-close_nav {
                    display: none;
                    position: absolute;
                    z-index: 1;
                    top: 20px;
                    right: 125px;
                    background-color: transparent;
                    outline: none;
                    border: 0;
                    padding: 0;
                    color: #D90037;
                    transition: 0.4s ease;

                    @media(min-width: 992px) {
                        display: block;

                        &:hover {
                            opacity: 0.6;
                        }
                    }
                }
            }

            .nav-categories_list {
                position: relative;
                z-index: 1;
                margin-bottom: 0;

                @media(min-width: 992px) {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    width: 100%;
                    max-width: 849px;
                }

                .nav-category_item {
                    margin-bottom: 24px;

                    @media(min-width: 992px) {
                        margin-bottom: 16px;
                        width: 30%;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .nav-category_link {
                        display: flex;
                        align-items: flex-start;
                        color: black;
                        text-decoration: none;

                        @media(min-width: 992px) {
                            &:hover {
                                .link_info {
                                    .name {
                                        color: #D90037;
                                    }
                                }
                            }
                        }

                        .icones {
                            text-align: center;
                            width: 32px;
                            min-width: 32px;
                            font-size: 22px;
                        }

                        .link_info {
                            margin-left: 16px;

                            .name {
                                display: block;
                                font-weight: 500;
                                font-size: 18px;
                                line-height: 22px;
                                margin-top: 6px;

                                @media(min-width: 992px) {
                                    font-size: 16px;
                                    line-height: 19px;
                                }
                            }

                            .desc {
                                display: block;
                                font-size: 13px;
                                line-height: 16px;
                                color: #4F4F4F;
                                margin-top: 8px;
                            }
                        }
                    }
                }

                &.azul{
                    .nav-category_item{
                        .nav-category_link {
                            @media(min-width: 992px) {
                                &:hover {
                                    .link_info {
                                        .name {
                                            color: #006CA9;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .desktop-items_wrapper {

                    @media(min-width: 992px) {
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        width: 30%;

                        .nav-category_item {
                            width: 45%;
                        }
                    }
                }
            }
        }
    }

    .nav-desktop_overlay {
        display: none;

        @media(min-width: 992px) {
            display: block;
            opacity: 0;
            visibility: hidden;
            position: fixed;
            z-index: 3;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #000000;
            transition: 0.6s ease;
        }
    }

    .nav-desktop_overlay {
        &.in {
            opacity: 0.75;
            visibility: visible;
        }
    }

    &.in {

        .header_nav {
            transform: translateY(0);
        }

        .header_main {
            .container {
                .top_content {
                    .right-side {
                        .menu_hamb-btn {
                            .bar1 {
                                transform: rotate(45deg) translateY(8px);
                            }

                            .bar2 {
                                transform: scale(0);
                            }

                            .bar3 {
                                transform: rotate(-45deg) translateY(-9px);
                            }

                        }
                    }
                }
            }
        }
    }

    &.logado {

        .header_main {
            .container {
                .top_content {
                    .right-side {
                        .user-info_btn {
                            display: flex;
                        }

                        .btn-entrar-cadastrar {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &-clube-saude {
        .header-categories_wrapper {
            .categories_list {
                .category_item {
                    width: 88px;
                    min-width: 88px;

                    @media(min-width: 992px) {
                        min-width: initial;
                        width: initial;
                    }
                }
            }
        }
    }
}

.header-nav_sublinks {
    position: fixed;
    z-index: 5;
    left: 0;
    top: 106.22px;
    width: 100%;
    height: calc(100vh - 106.22px);
    transform: translateX(-100%);
    transition: 0.5s ease;

    .header-nav_sublinks_list {
        position: relative;
        z-index: 2;
        background-color: white;
        padding: 24px;
        height: 100%;

        .header-nav_sublink-item {
            margin-bottom: 8px;
            padding-left: 24px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .header-nav_sublink {
                display: flex;
                align-items: center;
                color: black;
                text-decoration: none;
                font-size: 18px;
                font-weight: 500;
                line-height: 250%;

                .icone-chevron-right {
                    display: inline-block;
                    transform: rotateZ(180deg);
                    font-size: 14px;
                    color: #A2A2A5;
                    margin-right: 16px;
                }
            }
        }
    }

    &.in {
        transform: translateX(0);
    }
}

.header-overflow-handle {
    overflow: hidden;

    @media(min-width: 992px) {
        overflow: initial;
    }
}

.header-cadastro {
    position: relative;
    z-index: 2;
    background-color: #FFFFFF;
    border-bottom: 1px solid #E3E3E3;
    padding: 12px 0;

    @media(min-width: 992px) {
        padding: 16px 0;
    }

    .container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        @media(min-width: 992px) {
            justify-content: space-between;
        }

        .logo {
            max-width: 129px;
        }

        .logo_holder {
            .logo {
                margin-right: 10px;
            }
        }

        .ambiente-seguro_text {
            display: none;
            font-size: 14px;
            line-height: 120%;
            color: #6D6E71;
            margin-bottom: 0;

            @media(min-width: 992px) {
                display: block;
            }

            .icone-ambiente-seguro {
                margin-left: 12px;
            }
        }
    }

    &.header-cadastro-saude {
        padding: 0;
        height: 64px;

        @media(min-width: 992px) {
            height: 72px;
        }

        .container {
            height: 100%;

            @media(min-width: 992px) {
                justify-content: space-between;
            }
        }
    }
}

.fixed-header {
    position: fixed !important;
    width: 100%;
    left: 0;
}
