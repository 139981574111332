.modal-times { 
    &.fade {
        .modal-dialog {
            position:fixed;
            top:0;
            right:0;
            left:0;
            bottom:0;
            max-width: 390px;
            
            @media (min-width: 992px) {
                max-width: 655px;
            }            
        } 
    }  

    &.in {
        .modal-dialog {
            transform: translate3d(0, 0, 0);
        }
    }

    .modal-content {
        padding: 0;
        overflow-y: auto;
        max-height: calc(100vh - 15px);
        border-radius: 0 ;
        border: none;
        
        @media (min-width: 992px) {
            border-radius: 20px ;
        }
    }
     
    .modal-body {
        padding: 48px 24px 32px;
        position: relative;

        @media (min-width: 992px) {
            padding: 40px;
        }

        .icone-times {
            background-color: white;
            color: black;
            cursor: pointer;
            position: absolute;
            right: 16px;
            top: 24px;
        }

        .icone-seta-esquerda {
            color: #0061AA;
            cursor: pointer;
            transition: all ease-in-out .4s;

            &:hover {
                color: lighten(#0061AA, 10%);
            }
        }

        .titulo-indique {
            font-weight: 600;
            font-size: 32px;
            line-height: 120%;
            color: #000000;
            margin-top: 32px;
            margin-bottom: 24px;

            @media (min-width: 992px) {
                margin-top: 0;
            }
        }

        .titulo-indicar {
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
            color: #262626;
            margin-bottom: 24px;

            @media (min-width: 992px) {
                margin-bottom: 16px;
            }
        }

        .form-group {
            @media (min-width: 992px) {
                display: flex;
                margin-bottom: 24px;
            }
        }

        .form-nome {
            width: 100%;
            margin-bottom: 24px;

            @media (min-width:992px) {
                width: 227px;
                margin-right: 24px;
                margin-bottom: 0px;
            }
        }

        .form-email {
            width: 100%;
            margin-bottom: 24px;

            @media (min-width:992px) {
                width: 324px;
                margin-right: 24px;
                margin-bottom: 0px;
            }
        }

        .btn-oferta {
            width: 100%;
            background-color: #0061AA;
            color: white;
            transition: all ease-in-out .4s;
            margin-top: 32px;

            @media (min-width: 992px) {
                width: 173px;
            }

            &:hover {
                background-color: darken(#0061AA, 10%);
            }
        }
    }
}

.modal-simples {
    color: black;

    .modal-dialog {
        max-width: 557px;

        .modal-content {
            .modal-body {
                position: relative;
                padding: 32px 32px 48px;

                .btn-close {
                    position: absolute;
                    right: 24px;
                    top: 24px;
                    opacity: 1;
                    transition: 0.3s ease;

                    &:hover {
                        opacity: 0.75;
                    }
                }

                .modal_title {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 120%;
                    margin-bottom: 24px;
                }

                .instrucoes_list {
                    margin: 0;

                    .instrucoes_item {
                        font-size: 14px;
                        line-height: 140%;
                        margin-bottom: 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .resgate_box {
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    width: 100%;
                    text-decoration: none;
                    color: black;
                    background: #F7F7F7;
                    border-radius: 10px;
                    padding: 16px;
                    margin-bottom: 16px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    .image-gift {
                        width: 55px;

                        @media(min-width: 992px) {
                            width: initial;
                        }
                    }

                    .image-saldo {
                        width: 64px;

                        @media(min-width: 992px) {
                            width: initial;
                        }
                    }

                    .content {
                        margin: 0 12px 0 24px;

                        @media(min-width: 992px) {
                            margin: 0 24px 0 30px;
                        }

                        .box_name {
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 120%;
                            margin-bottom: 8px;                            
                        }

                        .box_desc {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 140%;
                            margin-bottom: 0;

                            @media(min-width: 992px) {
                                font-size: 14px;
                            }
                        }
                    }

                    .icone-chevron-right {
                        color: #CC092F;
                    }
                }
            }
        }
    }

}

.modal-remover-oferta {
    .modal-dialog {
        width: 358px;
        margin: 20px auto;

        @media (min-width: 992px) {
            width: 440px;
        }
        
    }

    .modal-content {
        .modal-body {
            text-align: center;
            padding: 32px;

            @media (min-width: 992px) {
                padding: 40px;  
            }

            .btn-area {
                display: flex;

                .btn {
                    width: 141px;
                    margin-right: 12px;

                    @media (min-width: 992px) {
                        margin-right: 14px;
                        width: 173px;
                    }

                    &:last-child {
                        margin-right: 0px;
                    }
                }
            }

            .btn-outline {
                border: 1px solid #88898C;
                background: white;
                color: #555658;
                border-radius: 5px;

                &:hover {
                    background-color: darken( white, 5%);
                }
            }

            .btn-azul {
                border-radius: 5px;
            }

            .titulo {
                font-size: 32px;
            }
        }
    }
}

.modal-estado {

    .modal-dialog {
        max-width: 367px !important;
        margin: auto;
    }

    .modal-body {
        padding: 40px !important;
    }

    .btn-azul {
        max-width: 287px !important;
    }
    
}

.modal-exp {

    .modal-dialog {
        width: 390px !important;
        margin: 0 auto;

        @media (min-width: 992px) {
            width: 496px !important;
        }
        
    }

    .modal-body {
        padding: 32px 32px 0px 32px !important;
        
        @media (min-width: 992px) {
            padding: 40px 32px !important;
        }
    }

    .modal-exp-texto {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        margin-bottom: 56px;

        @media (min-width: 992px) {
            font-size: 16px;
            margin-bottom: 64px;
        }
    }

    .btn-vermelho {
        width: 100%;
    }

    .filtro_radio-nps {      

        .input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;   
            
            &:checked {
                ~ .checkmark {                   
                    .mark_holder {
                        border: 1px solid #CC092F;
                        background-color: #CC092F;
                        color: white;
                        border-radius: 3.284px;
                    }
                }
            }
        }
    
        .checkmark {
            .mark_holder {
                display: flex;
                align-items: center;
                width: 30.864px;
                height: 30.864px;
                border-radius: 3.284px;
                border: 1px solid #A2A2A5;
                color: #333;
                text-align: center;
                font-size: 14px;
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 1.93px;
                cursor: pointer;
                transition: all ease-in-out .4s;

                @media (min-width: 992px) {
                    width: 39.497px;
                    height: 39.497px;
                    margin-right: 4px;
                    font-size: 16px;
                }
            }            
        }
    }

    .avaliacao {
        display: flex;
        flex-direction: column;
        margin-bottom: 64px;

        @media (min-width: 992px) {
            margin-bottom: 86px;
        }
    }

    .texto-recomendar {
        color: #88898C;
        font-size: 10px;
        font-weight: 400;
        line-height: 140%;
        margin-bottom: 0;
        margin-top: 8.1px;

        @media (min-width: 992px) {
            margin-top: 8.5px;
        }
    }

    .bcg_red {
        background-color: #CC092F;

        &:hover {
            background-color: darken(#CC092F, 10%);
        }
    }

    .bcg_amarelo {
        background-color: #FFCD04;

        &:hover {
            background-color: darken(#FFCD04, 10%);
        }
    }

    .bcg_verde {
        background-color: #03A100;

        &:hover {
            background-color: darken(#03A100, 10%);
        }
    }

    .form-modal {
        margin-bottom: 35px;

        @media (min-width: 992px) {
            margin-bottom: 28px;
        }
    }

    .filtro_radio-modal {      
        width: 100%;

        .input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;   
            
            &:checked {
                ~ .checkmark {                  
                    border-color: #CC092F;
                    color: #CC092F;  
                    
                    .mark_holder {
                        .mark {
                            border-color: #CC092F;
                            
                            &::after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        .checkmark {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;               
            font-weight: 400;
            font-size: 14px;
            line-height: 120%;        
            color: #555658;     
            white-space: nowrap;
            transition: 0.4s ease;

            &:hover {
                border-color: #CC092F;
            }

            .mark_holder {
                display: flex;
                align-items: center;

                .mark {
                    position: relative;
                    width: 14px;
                    min-width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    border: 1px solid #CC092F;
                    background-color: transparent;
                    margin-right: 8px;

                    &::after {                        
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        width: 8px;
                        min-width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: #CC092F;
                        transition: 0.4s ease;
                        opacity: 0;
                    }
                }
            }            
        }
    }

    .img-modal {
        width: 80px;
        height: 80px;
        margin-bottom: 16px;
        margin-top: 29px;
    }

    .textarea-modal {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 120%;
        padding: 14px 16px;
        border-radius: 5px;
        border: 1px solid #A2A2A5;
        height: 170px;
        margin-bottom: 24px;

        @media (min-width: 992px) {
            margin-bottom: 40px;
        }
    }

}

.biometria_modal {
    .modal-dialog {
        max-width: initial;
        height: 100%;
        margin: 0;
        padding: 0;

        .modal-content {
            border-radius: 0;
            height: inherit;

            .modal-header {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0;

                .logo {
                    max-width: 129px;
                }
            }

            .modal-body {
                position: relative;
                display: flex;
                align-items: flex-end; 
                justify-content: center;
                padding-bottom: 0;                  
                
                .intro {
                    margin: 24px 0 100px;
                    text-align: center;                   

                    .biometria_image {
                        display: block;
                        margin: 0 auto 24px;
                    }   
        
                    .title {
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 120%;
                        margin-bottom: 16px;
                    }
        
                    .text {
                        font-size: 14px;
                        line-height: 120%;
                        margin: 0;
                        color: #6D6E71;
                    }                     
                } 
                
                .bottom_holder {   
                    margin-bottom: 40px;           
                    .link-vermelho {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 51px;
                        font-size: 16px;                  
                    }             
                }

                .direitos-reservados_text {                    
                    font-size: 12px;
                    line-height: 15px;
                    color: #3C3D3E;
                    padding: 8px 0 9px;
                    margin-bottom: 0;
                    background-color: #FAFAFA;
                    text-align: center;
                }
            }
        }
    }
}

.modal-pix {

    .modal-dialog {

        @media (min-width: 768px) {
            max-width: 655px;
        }

    }

    .modal-body {
        padding: 16px 12px 24px;

        @media (min-width: 768px) {
            padding: 40px 40px 48px;
        }

        .btn-close {
            position: absolute;
            right: 16px;
            top: 10px;
            opacity: 1;
            transition: 0.3s ease;

            @media (min-width: 768px) {
                top: 24px;
            }

            &:hover {
                opacity: 0.75;
            }
        }

        .modal_title {
            color: #000;
            font-size: 24px;
            font-weight: 600;
            line-height: 120%;
            margin-bottom: 0;

            @media (min-width: 768px) {
                font-size: 32px;
            }
        }
    
        .form-modal {
            margin-bottom: 40px;
            display: flex;
    
        }
    
        .filtro_radio-modal {      
            margin-right: 14px;
            margin-top: 30px;

            @media (min-width: 768px) {
                margin-right: 40px;
                margin-top: 50px;
            }
    
            .input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;   
                
                &:checked {
                    ~ .checkmark {                  
                        border-color: #A2A2A5;
                        color: #CC092F;  
                        
                        .mark_holder {
                            .mark {
                                border-color: #A2A2A5;
                                
                                &::after {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
    
            .checkmark {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;               
                font-weight: 400;
                font-size: 14px;
                line-height: 120%;        
                color: #A2A2A5;     
                white-space: nowrap;
                transition: 0.4s ease;
    
                &:hover {
                    border-color: #A2A2A5;
                }
    
                .mark_holder {
                    display: flex;
                    align-items: center;
    
                    .mark {
                        position: relative;
                        width: 14px;
                        min-width: 14px;
                        height: 14px;
                        border-radius: 50%;
                        border: 1px solid #A2A2A5;
                        background-color: transparent;
                        margin-right: 8px;
    
                        &::after {                        
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            margin: auto;
                            width: 8px;
                            min-width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background-color: #CC092F;
                            transition: 0.4s ease;
                            opacity: 0;
                        }
                    }
                }       
                
                &_text {
                    font-size: 14px;
                    color: #000;
                    @media (min-width: 768px) {
                        font-size: 16px;
                    }

                }
            }
        }

        .input-form-group {
            width: 276px;
        }

        .group-email {
            .input-form-group {
                margin-bottom: 16px;

                &:last-of-type {
                    margin-bottom: 0;
                }

                @media (min-width: 768px) {
                    margin-right: 24px;
                    margin-bottom: 0;
                    min-width: 275px;

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }

        .btn-vermelho {
            width: 276px;
            margin-top: 32px;
            font-weight: 500;
        }
    }
}

.modal-tooltip {

    .modal-dialog {
        max-width: 303px;
        margin: auto;

        .modal-content {
            .modal-body {
                padding: 32px;

                .btn-close {
                    top: 12px;
                    right: 13px;
                }
   
                .modal_title {
                    color: #000;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 120%;
                    margin-bottom: 0;
                    text-align: center;
                }
            }
        }
    }
}

.modal-chave-pix {
    .modal-dialog {
        margin: auto;
        max-width: 342px;

        @media (min-width: 992px) {
            max-width: 424px;
        }

        .modal-content {
            .modal-body {
                padding: 24px;
    
                @media (min-width: 992px) {
                    padding: 32px;
                }
                
                .modal_title {
                    color: #000;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 120%;
                    margin-bottom: 16px;
                }
    
                .texto {
                    color: #000;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 140%;
                    margin-bottom: 24px;
    
                    @media (min-width: 992px) {
                        font-size: 16px;
                        max-width: 350px;
                    }
                }
    
                .btn {
                    height: 56px;
                }

                .form-check-label {
                    color: #3C3D3E;
                    font-size: 14px;
                }

            }
        }
    }
}

.modal-video{
    .modal-dialog {
        @media (min-width: 768px) {
            max-width: 742px;
        }

        .modal-content{
            background-color: transparent;
            border: 0;
        }
    }
    .modal-header{
        background-color: transparent;
        border: 0;
        outline: none;
        padding: 0 0 8px;

        .btn-close{
            background-color: transparent;
            background-image: none;
            opacity: 1;
            line-height: 1;
            width: auto;
            height: auto;
            transition: all .3s ease;

            &:hover{
                opacity: .5;
            }

            .icone-fechar{
                width: 32px;
                height: 32px;
            }
        }
    }
    .modal-body {
         padding: 0;
         border-radius: 8px;
         overflow: hidden;

         .video{
            width: 100%;
            border-radius: 8px;
         }
    }
}

.modal-alerta {
    color: black;

    .modal-dialog {
        max-width: 548px;

        .modal-content {
            border-radius: 20px;
            .modal-body {
                padding: 16px 16px;

                @media (min-width: 992px) {
                    padding: 16px 16px 24px;
                }

                .modal-banner{
                    height: 148px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 8px;
                    background: linear-gradient(270deg, #CC092F -28.32%, #CC092F 35.72%, #9A1624 82.36%, #991624 97.85%);
                    margin-bottom: 16px;
                    border-radius: 16px;

                    @media (min-width: 992px) {
                        height: 252px;
                        margin-bottom: 24px;
                    }

                    &-info{
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      justify-content: center;
                      padding-left: 20px;

                      @media (min-width: 992px) {
                        padding-left: 32px;
                      }
                    }
                    
                    .modal-logo{
                        width: 103px;
                        margin-bottom: 8px;

                        @media (min-width: 992px) {
                            width: 129px;
                            margin-bottom: 20px;
                        }
                    }

                    .modal-banner-img{
                       height: 124px;

                       @media (min-width: 992px) {
                        height: 228px;
                       }
                    }

                    .modal-title {
                        color: #FFF;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 120%;
                        margin-bottom: 0;

                        @media (min-width: 992px) {
                            font-size: 32px;
                        }
                    }
                }
               
                .modal-text{
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    margin-bottom: 24px;

                    @media (min-width: 992px) {
                        font-size: 18px;
                    }
                }
                .btn-vermelho{
                    width: 100%;
                    font-size: 16px;

                    @media (min-width: 992px) {
                        max-width: 350px;
                    }
                }
            }
        }
    }
}