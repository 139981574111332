.cashback_table {
    margin-bottom: 32px;
    
    thead {
        background-color: #F9F9F9;

        tr {           

            th {
                padding: 24px 10px;
                border: 0;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;              
                color: #6D6E71;

                @media(min-width: 992px) {
                    padding: 24px 32px;
                }

                &:first-child {
                    border-radius: 10px 0 0 0;
                }

                &:last-child {
                    border-radius: 0 10px 0 0;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 24px 10px;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #000000;

                @media(min-width: 992px) {
                    padding: 24px 32px;
                    font-size: 16px;
                    line-height: 19px;
                }

                .c-verde {
                    color: #03A200;
                }

                .c-azul {
                    color: #0061AA;
                }

                .c-cinza {
                    color: #A2A2A5;
                }

                .status_text {
                    display: flex;
                    align-items: center;

                    .status_icon {
                        margin-right: 8px;
                        margin-top: 2px;
                    }
                }
            }
        }
    }
}