.login {
    .form-group {
        position: relative;
    
        .form-control {
            padding-right: 42px;
        }
    
       
    }
}

.form-select {
    height: 47px;
    border: 1px solid #A2A2A5;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #262626;
    padding: 0 16px;
}


    .password-strength_wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;

        .strength-bar_holder {
            width: 100%;
            height: 3px;
            background-color: #D5D6D7;  
            margin-right: 20px;   
            
            .bar {                
                height: 3px;
                
                &.weak {
                    background-color: #CC092F;
                    width: 33.33%;
                }

                &.medium {
                    background-color: #F8733A;
                    width: 66.66%;
                }

                &.strong {
                    background-color: #03A200;
                    width: 100%;
                }
            }
        }

        .strength_text {
            margin-bottom: 0;
            font-size: 12px;
            line-height: 120%;
            color: #555658;
        }
    }

    .disclaimer {
        font-size: 12px;
        line-height: 120%;
        color: #A2A2A5;
        margin: 8px 0 0;
    }

    .filtro_radio-v2 {      
        width: 100%;

        .input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;   
            
            &:checked {
                ~ .checkmark {                  
                    border-color: #CC092F;
                    color: #CC092F;  
                    
                    .mark_holder {
                        .mark {
                            border-color: #CC092F;
                            
                            &::after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        .checkmark {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;               
            border: 1px solid #D5D6D7;
            border-radius: 6px;   
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;        
            color: #3C3D3E;     
            padding: 20px 30px 20px 18px; 
            white-space: nowrap;
            transition: 0.4s ease;

            &:hover {
                border-color: #CC092F;
            }

            .mark_holder {
                display: flex;
                align-items: center;

                .mark {
                    position: relative;
                    width: 17px;
                    min-width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    border: 2px solid #A2A2A5;
                    background-color: transparent;
                    margin-right: 24px;

                    &::after {                        
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        width: 9px;
                        min-width: 9px;
                        height: 9px;
                        border-radius: 50%;
                        background-color: #CC092F;
                        transition: 0.4s ease;
                        opacity: 0;
                    }
                }
            }            

            .icones {
                font-size: 30px;
            }
        }
    }

    .input-form-group {
        position: relative;

        .icone-calendario {
            pointer-events: none;
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
        }

        .custom-label {
            pointer-events: none;
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            padding: 1px 6px;           
            font-size: 16px;
            line-height: 19px;
            color: #B3B3B3;

            &.label-transition {
                transition: 0.2s ease;
            }           

            &.out {
                top: -8.5px;
                transform: translateY(0);
                font-size: 12px;
                line-height: 15px;             
                color: #262626;
                background-color: white;
            }    
        }

        .form-control {
            &:placeholder-shown ~ .input_label {                
                font-size: 16px;
                line-height: 19px;
                top: 50%;
                transform: translateY(-50%);               
            }

            &:focus ~ .input_label {
                top: -8.5px;
                transform: translateY(0);
                font-size: 12px;
                line-height: 15px;       
                background-color: white;
            }
        }

        .input_label {   
            pointer-events: none;
            position: absolute;
            padding: 1px 6px;            
            color: #B3B3B3;
            transition: 0.2s ease;
            left: 10px;  
            z-index: 5;          
            top: -8.5px;
            transform: translateY(0);
            font-size: 12px;
            line-height: 15px;       
            background-color: white;
        }

        .error_icon, .error_text {
            display: none;
        }      
    }

    .text-area-form {
        .form-control {
            min-height: 205px;
            padding-top: 14px;

            &:placeholder-shown ~ .input_label {          
                top: 14px; 
                transform: translate(0);                         
            }  
            
            &:focus ~ .input_label {
                top: -8.5px;
                transform: translateY(0);               
            }
            
            ~ .input_label {
                top: -8.5px;
            }
        }         
        
    }

    .input-error {
        .input-form-group {
            .form-control {
                padding-right: 40px;
                border: 1px solid #CC092F;
            }

            .form-select {
                padding-right: 40px;
                border: 1px solid #CC092F;
            }

            .error_icon {
                display: block;
            }

            .error_icon {
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
            }           
        }

        .form-check {
            .form-check-input {
                border-color: #CC092F;
            }
        }  
        
        .error_text {
            display: block;
        }
    }

    .error_text {
        display: none;
        margin: 4px 0 0;
        font-size: 14px;
        line-height: 120%;             
        color: #CC092F;
    } 

    .input-with-icon {
        .input-form-group {
            .form-control {
                padding-right: 72px;
            }

            .form-select {
                padding-right: 72px;
            }

            .error_icon {
                right: 44px;
            }
        }
    }

    .form-check-blue {
        .form-check-input{
            width: 19px;
            height: 19px;
            border: 1.5px solid #999999;
            border-radius: 3px;
            transition: all .3s ease;         
    
            &:checked{
                background-color: #2D9DE9;
                border-color: #2D9DE9;
            }
    
            &:focus{
                box-shadow: 0 0 0 0.25rem rgba(45, 158, 233, 0.25);               
            }
        }

        .form-check-label {
            margin-left: 12px;
        }
    }

    




