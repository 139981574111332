/* ==========================================================================
   Cards
   ========================================================================== */
.card {
    overflow: hidden;
    max-width: 310px;
    padding: 0;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    border-radius: 10px;
    overflow: hidden;
    min-height: 388px;
    text-decoration: none;
    transition: all ease-in-out .4s;

    @media (min-width: 992px) {
        max-width: 275px;
        min-height: 368px;
        max-height: 368px;
    }

    &:last-of-type {
        margin-right: 0;
    }

    &:hover {
        filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.01)) drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.05)) drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.09)) drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1))
    }

    &-img {
        position: relative;
        width: 100%;
        height: 160px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &-body {
        position: relative;
        padding: 16px 20px 40px;
        font-size: 14px;
        line-height: 20px;

        @media (min-width: 992px) {
            padding: 16px 20px;
        }

        .favorite_icon {
            position: absolute;
            right: 16px;
            top: 23px;
        }
    }

    &-tag {
        display: inline-flex;
        align-items: center;
        height: 36px;
        padding: 6px 16px 6px 18px;
        font-weight: 600;
        font-size: 11px;
        line-height: 13px;
        text-transform: uppercase;
        text-transform: uppercase;
        margin-bottom: 16px;
        border-radius: 135px;
        border: 1px solid;
        color: white;

        .icones{
            font-size: 18px;
        }

        &-texto {
            margin-bottom: 0;
            margin-left: 12px;
        }

        &.tag-azul-clara {
            border-color: #2D9DE9;
            color: #2D9DE9;
            background-color: #F1F9FE;
        }

        &.tag-azul-escura {
            border-color: #154575;
            color: #154575;
            background-color: #E9F2FB;
        }

        &.tag-vermelha {
            border-color: #CC092F;
            color: #CC092F;
            background-color: #FEF0F3;
        }
        &.tag-azul-100 {
            border-color: #006CA9;
            color: #006CA9;
            background-color: #FEF0F3;
        }

        &.tag-verde {
            border-color: #03A100;
            color: #03A100;
            background-color: #E6FFE5;
        }
        &.tag-cinza {
            border-color: #4F4F4F;
            color: #4F4F4F;
            background-color: #F7F7F7;
        }

        &.tag-central {
            border-color: #154575;
            color: #154575;
            background-color: #E9F2FB;
        }
    }

    &-titulo {
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
    }

    &-desc {
        margin-bottom: 0;
    }

    &-produto {
        .card-img {
            height: 220px;
            background-size: auto;
        }

        .card-body {


            .valores_wrapper {
                display: flex;
                align-items: center;
                margin-bottom: 18px;

                .card-tag {
                    margin-bottom: 0;
                    margin-right: 13px;
                    padding: 6px 14px 6px 12px;
                    border-radius: 4px;

                    .card-tag-texto {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 22px;
                        margin: 0;
                    }
                }

                .old-value_text {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #191919;
                    margin-bottom: 0;

                    .value {
                        text-decoration: line-through;
                    }
                }
            }
        }
    }

    &-oferta-deslogado {
        position: relative;

        &:hover {
            .card-deslogado_info {
                opacity: 1;
                visibility: visible;

                .info_intro, .nao-possui-login_text {
                    transform: translateY(0);
                }
            }
        }

        .card-deslogado_info {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            z-index: 2;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color:#4D4D4D;
            padding: 84px 20px 32px;
            text-align: center;
            color: white;
            transition: 0.4s ease;
            opacity: 0;
            visibility: hidden;

            .info_intro {
                transition: 0.4s ease;
                transform: translateY(30px);

                .info_desc {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    margin-bottom: 24px;
                }
            }

            .nao-possui-login_text {
                transition: 0.4s ease;
                margin-bottom: 0;
                font-size: 16px;
                line-height: 19px;
                transform: translateY(30px);

                .link {
                    color: white;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }


            &.copiar{
                padding: 102px 20px 32px !important;

                .info_intro{
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    margin-bottom: 0;

                    .info_desc{
                        width: 90%;
                        margin-bottom: 0;
                    }
                }

                .cupom{
                    width: 100%;
                    display: flex;
                    padding: 16px 32px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    border: 1.7px dashed rgba(217, 217, 217, 0.50);
                    background: rgba(255, 255, 255, 0.10); 
    
                    &-valor{
                        font-size: 18px;
                        margin-bottom: 0;
                    }
                }

                .btn{width: 100%;}
            }
        }
    }
}

.chamada_card {
    position: relative;
    display: block;
    width: 100%;
    color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    height: 400px;
    text-decoration: none;
    padding: 24px;

    &-black-color {
        color: #191919;
    }

    @media(min-width: 992px) {
        height: 271px;
        padding: 32px;
        margin-bottom: 24px;
        transition: 0.4s ease;

        &:hover {
            opacity: 0.8 !important;
        }
    }

    .card_content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media(min-width: 992px) {
            height: 100%;
        }

        .card_info {

            @media(min-width: 992px) {
                width: 60%;
                margin-bottom: 16px;
            }

            .categoria {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 8px;
            }

            .card_title {
                font-weight: 700;
                font-size: 22px;
                line-height: 27px;
                margin-bottom: 0;

                @media(min-width: 992px) {
                    font-size: 24px;
                    line-height: 29px;
                }
            }

            .card_text {
                font-size: 16px;
                line-height: 22px;
                margin-top: 8px;
                margin-bottom: 0;
            }

            &.width{
                @media(min-width: 992px) {
                    width: 40%;
                }
            }
        }

        .acessar_text {
            display: none;

            @media(min-width: 992px) {
                display: block;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
            }
        }
    }

    .card_content-mobile-black {
        @media(max-width: 991px) {
            color: black;
        }
    }
}

.resgate_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 32px auto 0;
    min-width: 340px;
    max-width: 340px;

    @media(min-width: 992px) {
        min-width: 460px;
        max-width: 460px;
        margin: 0;
    }

    .resgate-card_info {
        width: 100%;
        background-color: white;
        border-radius: 20px 20px 0 0;
        padding: 40px 26px 32px;

        @media(min-width: 992px) {
            padding: 40px 35px 32px;
            margin: 0;
        }

        .input-cupom_holder {
            position: relative;
            width: 100%;
            margin-bottom: 20px;

            .input-cupom {
                width: 100%;
                height: 56px;
                border-radius: 5px;
                background: #F7F7F7;
                border: 2px dashed #6D6E71;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                text-transform: uppercase;
                color: #000000;
                outline: none;
                padding: 0 45px;
            }

            .status-icon {
                display: none;
                position: absolute;
                top: 50%;
                right: 16px;
                transform: translateY(-50%);
            }

            &.success {
                .input-cupom {
                    border: 2px solid #03A200;
                }

                .status-icon {
                    display: block;
                }
            }

            &.error {
                .input-cupom {
                    border: 2px solid #CC092F;
                }

                .status-icon {
                    display: block;
                }
            }
        }

        .preencha_text {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #2B2B2B;
            margin-bottom: 24px;
        }

        .cupom_name {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #000000;
            margin-bottom: 16px;
        }

        .infos_text {
            font-size: 16px;
            line-height: 19px;
            color: #262626;
            margin-bottom: 0;

            .icon {
                margin-right: 8px;
            }
        }

        .infos_wrapper {
            display: flex;
            align-items: center;
            margin-top: 16px;
            margin-bottom: 30px;

            .infos_text {
                &:first-child {
                    margin-right: 24px;
                }
            }
        }
    }

    .img-end {
        width: 100%;
    }
}
