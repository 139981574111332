.footer {
    background-color: #FAFAFA;
    font-family: 'bradescosans-regular',sans-serif;

    &-imgbradesco {
        margin-top: 40px;
        max-width: 257px;

        @media (min-width: 992px) {
            margin-top: 40px;
            margin-bottom: 32px;
        }
    }

    &-bradesco {
        display: flex;
        justify-content: center;

        @media (min-width: 992px) {
            display: block;
        }
    }

    &-endereco {
        max-width: 274px;
        font-size: 12px;
        line-height: 19px;
        margin-bottom: 40px;
    }

    &-links {
        list-style: none;
        padding: 0;
        margin-top: 40px;

        &:first-of-type {
            padding-left: 35px;
        }

    }

    .links-titulo {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        margin-bottom: 16px;
        text-align: center;

        @media (min-width: 992px) {
            text-align: left;
        }
    }

    .link {
        font-size: 14px;
        line-height: 19px;
        color: #4F4F4F;
        text-decoration: none;
        transition: all ease-in-out .3s;

        &:hover {
            color: #D90037;
        }
    }

    &-midias {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: 992px) {
            display: block;
        }

        .app-down {
            margin-right: 38px;

            &:last-of-type {
                margin-right: 0;
            }
        }

        .redes-sociais {
            margin-right: 22px;
            margin-top: 8px;
            text-decoration: none;

            @media (min-width: 992px) {
                margin-right: 18px;
            }

            @media (min-width: 1200px) {
                margin-right: 22px;
            }

            &:last-of-type {
                margin-right: 0;
            }

            .icones {
                color: black;
                font-size: 24px;
            }

            &:hover {
                .icones {
                    color: #D90037;
                }
            }
        }

    }

    &-copyright {
        background-color: #CC092F;
        color: white;
        font-size: 13px;
        line-height: 17px;
        padding: 16px 0;
        text-align: center;

        @media (min-width: 992px) {
            padding: 12px 0;
            font-size: 14px;
            text-align: left;
        }

        &.bg-escuro{
            background-color: #262626;
        }

        .container {
            display: flex;
            align-items: center;
            flex-direction: column-reverse;

            @media(min-width: 992px){
                flex-direction: row;
                gap: 32px;
                justify-content: space-between;
            }

            .links-wrapper {
                display: flex;
                flex-direction: column;
                gap: 8px;
                margin-bottom: 16px;

                @media(min-width: 992px){
                    flex-direction: row;
                    gap: 20px;
                    margin-bottom: 0;
                }

                .documento-link {
                    color: #FFF;                  
                    font-size: 14px;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &.footer-mediservice-azul{
        .link {
    
            &:hover {
                color: #006CA9;
            }
        }
    }
}
