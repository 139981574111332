.home_banner {
    display: flex;
    align-items: flex-start;
    height: 100%;
    // background-image: url('../img/bg-banner-principal-mobile.svg');
    // background-position: top left;
    // background-size: cover;
    // background-repeat: no-repeat;

    @media(min-width: 992px) {
        // background-image: url('../img/bg-banner-principal.svg');
        align-items: center;
        height: 530px;
    }

   .banner_content-wrapper {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        color: white;

        @media(min-width: 992px) {
            flex-direction: row;
        }

        .banner_content {
            position: relative;
            padding: 24px 0 66px;

            @media(min-width: 992px) {
                max-width: 341px;
                margin-right: 84px;
                padding: 0 0 32px;
            }

            .banner-marca_intro {
                display: flex;
                align-items: center;
                margin-bottom: 8px;

                @media(min-width: 992px) {
                    margin-bottom: 16px;
                }

                .logo_holder {
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 72px;
                    height: 72px;
                    min-width: 72px;
                    border-radius: 5px;
                    border: 2px solid white;                
                    margin-right: 24px;

                    @media(max-width: 991px) {
                        position: absolute;
                        right: 0;
                        top: -40px;
                        margin-right: 0;
                    }
                }

                .marca {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    margin-bottom: 0;

                    @media(min-width: 992px) {
                        font-size: 24px;
                        line-height: 29px;
                    }
                }
            }

            .banner_title {
                font-size: 2rem;
            }

            .banner_desc {
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 24px;

                @media(min-width: 992px) {
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 32px;
                }
            }
        }

        .banner_right-side {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;

            @media(min-width: 992px) {
                width: initial;
            }

            .banner-img_holder {
                overflow: hidden;
                width: 100%;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 200px;

                @media(min-width: 992px) {
                    width: 450px;
                    height: initial;
                    margin-bottom: 16px;
                    border-radius: 20px;


                    &::after {
                        content: '';
                        display: block;
                        padding-bottom: 60%;
                    }
                }

                @media(min-width: 1200px) {
                    width: 617px;
                }
            }

            .ofertas-localidade_text {
                display: none;
                margin-bottom: 0;
                font-weight: 500;
                font-size: 12px;
                line-height: 19px;

                @media(min-width: 992px) {
                    display: block;
                }

                .localidade_link {
                    color: white;
                    transition: 0.4s ease;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
   }
}

.banner_interna {
    width: 100%;
    background: linear-gradient(89.95deg, #CC092F 0.03%, #CC092F 42.07%, #B80E80 90.16%);
    height: 142px;

    @media(min-width: 992px) {
        height: 145px;
    }

    &.mediservice {
        background: linear-gradient(90deg, #03527F 0.03%, #006CA9 42.07%, #B2F337 90.16%);
    }

    .container {
        display: flex;
        align-items: center;
        position: relative;
        height: 100%;

        .title {
            font-weight: 700;
            font-size: 32px;
            line-height: 39px;
            color: #FFFFFF;
            margin-bottom: 0;

            @media(min-width: 992px) {
                font-size: 40px;
                line-height: 49px;
            }

            .br-mobile-only {
                @media(min-width: 992px) {
                    display: none;
                }
            }
        }

        .img-flutuante {
            position: absolute;
            bottom: -30px;
            right: 27px;
            max-width: 162px;

            @media (min-width: 992px){
                bottom: -80px;
                right: 0;
                max-width: initial;
            }
        }
    }
}

.circuito-cultural_home-banner {
    padding: 32px 0 178px;
    background-image: url('../img/bg-banner-circuito-cultural.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media(min-width: 992px) {
        padding: 0;
        height: 530px;
    }

    .container {
        height: 100%;

        @media(min-width: 992px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .content {
            color: white;
            text-align: center;
            max-width: 360px;
            margin: 0 auto;

            @media(min-width: 992px) {
                max-width: 466px;
                text-align: initial;
                margin-right: 32px;
                margin-left: 0;
            }

            .banner_title {
                font-weight: 700;
                font-size: 32px;
                line-height: 39px;
                margin-bottom: 8px;

                @media(min-width: 992px) {
                    font-size: 48px;
                    line-height: 58px;
                    margin-bottom: 16px;
                    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
                }
            }

            .banner_text {
                font-size: 14px;
                line-height: 120%;
                margin-bottom: 0;

                @media(min-width: 992px) {
                    font-size: 18px;
                    line-height: 22px;
                }
            }
        }
    }
}

.small_banner {
    position: relative;
    width: 100%;
    height: 232px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    padding-top: 41px;
    margin-bottom: 32px;

    color: white;

    @media(min-width: 768px) {        
        margin-bottom: 51px;
    }

    @media(min-width: 992px) {
        height: 275px;
        margin-bottom: 59px;
    }

    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        background-color: black;
        opacity: 0.5;
    }

    .container {
        position: relative;
        z-index: 2;
        
        .back_link {
            display: inline-flex;
            align-items: center;
            text-decoration: none;
            color: white;
            font-size: 14px;
            font-weight: 600;
            line-height: 120%;
            margin-bottom: 35px;  
            
            .icones {
                margin-right: 6px;
                transition: 0.3s;
            }

            &:hover {
                .icones {
                    transform: translateX(-5px);
                }
            }
        }

        .title {
            max-width: 460px;
            font-size: 24px;      
            font-weight: 400;
            margin-bottom: 0;   

            @media(min-width: 992px) {
                font-size: 32px;
            }
        }
    }
}
.home-banner_carousel{
    background-image: url('../img/bg-banner-principal-mobile.svg');
    background-position: bottom;
    background-size: 105% auto;
    background-repeat: no-repeat;

    @media(min-width: 992px) {
        background-size: cover;
        background-image: url('../img/bg-banner-principal.svg');
    }

    &.mediservice {
        background-image: url('../img/bg-carousel-banner-mediservice-mobile.svg');

        @media(min-width: 992px) {      
            background-image: url('../img/bg-carousel-banner-mediservice.svg');
        }
    }

    &.mediservice-novo{
        background-image: url('../img/bg-carousel-banner-mediservice-novo.svg');
    }

    &.banner-corretor{
        background-color: #0E1E64;
        background-image: none;

        @media(min-width: 992px) {      
            background-image: url('../img/bg-carousel-banner-corretor.svg');
        }
    }

    

}