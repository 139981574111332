.tooltip-vermelho {
   

    .tooltip-arrow {
        display: none;
    }

    .tooltip-inner {
        background-color: #CC092F;
        border-radius: 5px;
        color: white;
        font-size: 12px;
        line-height: initial;
        padding: 4px 12px;
        margin-bottom: 8px;
    }
}

.tooltip-login-preto {

    .tooltip-arrow {
        top: 3px !important;
    }
    
    .tooltip-inner {
        background-color: #000;
        border-radius: 6px;
        color: #F7F7F7;
        font-size: 12px;
        padding: 13px;
        font-weight: 600;
        line-height: 120%;
        text-align: center;
        margin-top: 10px;
    }
}