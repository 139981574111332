//ACCORDION FAQ
.accordion-faq {
  margin-bottom: 80px;

  @media (min-width: 992px) {
    margin-bottom: 120px;
  }
  
  .accordion-item {
    margin-bottom: 8px;
    border: 1px solid #D5D6D7;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .accordion-header {
    padding: 0;
    margin: 0;
  }

  .accordion-button {
    width: 100%;
    padding: 24px 22px 8px 24px;
    background-color: #CC092F;;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    text-decoration: none;
    color: white;
    position: relative;
    border: none;
    border-radius: 0;

    @media(min-width: 992px) {
      line-height: 120%;
      padding: 24px 22px 24px 24px;
    }

    &:focus {
      box-shadow: none;
    }

    &::after {
      display: none;
    }

    &_conteudo {
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;

      span:first-child {
        text-align: left;
      }

    }   

    .times {
      display: none;
    }     

    &.collapsed {
      color: #555658;
      background-color: white;
      padding: 24px 22px 24px 24px;
      font-weight: 500;
      transition: all ease-in-out 0.4s;

      &:hover {
        background-color: #D5D6D7;
      }    
          
      .times {
        display: none;
      }         
    }

    &:not(.collapsed) {
      
      .times {
        display: block;
      }

      .chevron {
        display: none;
      }      
    }
  }

  .accordion-body {
    padding: 0px 60px 24px 24px;
    font-weight: 400;
    font-size: 13px;
    line-height: 160%;
    background-color: #CC092F;
    color: #FFFFFF;

    @media (min-width: 992px) {
      padding: 0px 24px 32px 24px;
    }

    p {
      max-width: 622px;
    }

  }

  .accordion-collapse {
    border: none;
    border-radius: 0;
  }

}

