// Medias Queries
@mixin max320 {
    @media screen and (max-width:320px){
        @content;
    }
}

@mixin max479 {
    @media screen and (max-width:479px){
        @content;
    }
}

@mixin min480 {
    @media screen and (min-width:480px){
        @content;
    }
}

@mixin max767 {
    @media screen and (max-width:767px){
        @content;
    }
}

@mixin min768 {
    @media screen and (min-width:768px){
        @content;
    }
}

@mixin max991 {
    @media screen and (max-width:991px){
        @content;
    }
}

@mixin min992 {
    @media screen and (min-width:992px){
        @content;
    }
}

@mixin min1200 {
    @media screen and (min-width:1200px){
        @content;
    }
}
@mixin min1440 {
    @media screen and (min-width:1440px){
        @content;
    }
}

@mixin min1600 {
    @media screen and (min-width:1600px){
        @content;
    }
}

@mixin min1920 {
    @media screen and (min-width:1920px){
        @content;
    }
}

