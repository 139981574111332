.user-info_btn {
    &.show {
        ~ .user-dropdown {
            opacity: 1;
        }
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    } 

    100% {
        opacity: 1;
    }
}

.user-dropdown {
    background-color: #CC092F;
    border: 0;    
    border-radius: 0 0 10px 10px;
    padding-bottom: 24px;
    margin-top: -2px !important;
    opacity: 0;    
    animation: fade-in 0.6s ease forwards;

    .user-dropdown_division {
        padding: 0 16px;  
        
        .linha {
            border-color: white;
            opacity: 0.6;
            margin: 17px 0 8px;
        }
    }

    .user-dropdown_item {
        padding: 0 24px;

        .user-dropdown_link {
            color: white;
            text-decoration: none;
            font-weight: 500;
            font-size: 16px;
            line-height: 260%;
            transition: 0.4s ease;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    &_azul{
        background-color: #006CA9;
    }
}