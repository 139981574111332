.filtros_wrapper {
    display: flex;
    flex-wrap: nowrap;
    border: 0;
    overflow-x: auto;
    padding: 10px 0; 

    .filtro_radio {
        margin-right: 16px;

        &:last-child {
            margin-bottom: 0;
        }

        .input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;   
            
            &:checked {
                ~ .checkmark {
                    background-color: #CC092F;
                    border-color: #CC092F;
                    color: white;
    
                    &:hover {
                        background-color: #CC092F;
                    }
                }
            }
        }

        .checkmark {
            cursor: pointer;
            text-align: center;
            background-color: #FAFAFA;
            border: 2px solid rgba(79, 79, 79, 0.2);
            border-radius: 10px;    
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #555658;
            padding: 16px 24px; 
            white-space: nowrap;
            transition: 0.4s ease;

            &:hover {
                background-color: #F1F1F1;
            }
        }
    }

    &_azul{

        .filtro_radio{
            .input {
            
                &:checked {
                    ~ .checkmark {
                        background-color: #006CA9;
                        border-color: #006CA9;
                        color: white;
        
                        &:hover {
                            background-color: #006CA9;
                        }
                    }
                }
            }
        }
    }
}

