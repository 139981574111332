/*!
 * Tipografia
 * Clube de Vantagens V03
 * Desenvolvido por Fattoria em: 15/05/2023
 */

/* ==========================================================================
   Tipografia
   ========================================================================== */

/* Bradesco Sans Bold */
@font-face {
    font-family: 'bradescosans-bold';
    src: url('../fonts/bradesco_sans/BradescoSans-Bold.eot');
    src: url('../fonts/bradesco_sans/BradescoSans-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bradesco_sans/BradescoSans-Bold.woff') format('woff'),
         url('../fonts/bradesco_sans/BradescoSans-Bold-web.ttf') format('truetype'),
         url('../fonts/bradesco_sans/BradescoSans-Bold.svg#bradescosans-bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Bradesco Sans Bold Italic */
@font-face {
    font-family: 'bradescosans-bolditalic';
    src: url('../fonts/bradesco_sans/BradescoSans-BoldItalic.eot');
    src: url('../fonts/bradesco_sans/BradescoSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bradesco_sans/BradescoSans-BoldItalic.woff') format('woff'),
         url('../fonts/bradesco_sans/BradescoSans-BoldItalic-web.ttf') format('truetype'),
         url('../fonts/bradesco_sans/BradescoSans-BoldItalic.svg#bradescosans-bolditalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Bradesco Sans Condensed */
@font-face {
    font-family: 'bradescosans-condensed';
    src: url('../fonts/bradesco_sans/BradescoSans-Condensed.eot');
    src: url('../fonts/bradesco_sans/BradescoSans-Condensed.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bradesco_sans/BradescoSans-Condensed.woff') format('woff'),
         url('../fonts/bradesco_sans/BradescoSans-Condensed-web.ttf') format('truetype'),
         url('../fonts/bradesco_sans/BradescoSans-Condensed.svg#bradescosans-condensed') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Bradesco Sans Condensed Bold */
@font-face {
    font-family: 'bradescosans-condensedbold';
    src: url('../fonts/bradesco_sans/BradescoSans-CondensedBold.eot');
    src: url('../fonts/bradesco_sans/BradescoSans-CondensedBold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bradesco_sans/BradescoSans-CondensedBold.woff') format('woff'),
         url('../fonts/bradesco_sans/BradescoSans-CondensedBold-web.ttf') format('truetype'),
         url('../fonts/bradesco_sans/BradescoSans-CondensedBold.svg#bradescosans-condensedbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Bradesco Sans Light */
@font-face {
    font-family: 'bradescosans-light';
    src: url('../fonts/bradesco_sans/BradescoSans-Light.eot');
    src: url('../fonts/bradesco_sans/BradescoSans-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bradesco_sans/BradescoSans-Light.woff') format('woff'),
         url('../fonts/bradesco_sans/BradescoSans-Light-web.ttf') format('truetype'),
         url('../fonts/bradesco_sans/BradescoSans-Light.svg#bradescosans-light') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Bradesco Sans Light Italic */
@font-face {
    font-family: 'bradescosans-lightitalic';
    src: url('../fonts/bradesco_sans/BradescoSans-LightItalic.eot');
    src: url('../fonts/bradesco_sans/BradescoSans-LightItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bradesco_sans/BradescoSans-LightItalic.woff') format('woff'),
         url('../fonts/bradesco_sans/BradescoSans-LightItalic-web.ttf') format('truetype'),
         url('../fonts/bradesco_sans/BradescoSans-LightItalic.svg#bradescosans-lightitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Bradesco Sans Medium */
@font-face {
    font-family: 'bradescosans-medium';
    src: url('../fonts/bradesco_sans/BradescoSans-Medium.eot');
    src: url('../fonts/bradesco_sans/BradescoSans-Medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bradesco_sans/BradescoSans-Medium.woff') format('woff'),
         url('../fonts/bradesco_sans/BradescoSans-Medium-web.ttf') format('truetype'),
         url('../fonts/bradesco_sans/BradescoSans-Medium.svg#bradescosans-medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Bradesco Sans Medium Italic */
@font-face {
    font-family: 'bradescosans-mediumitalic';
    src: url('../fonts/bradesco_sans/BradescoSans-MediumItalic.eot');
    src: url('../fonts/bradesco_sans/BradescoSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bradesco_sans/BradescoSans-MediumItalic.woff') format('woff'),
         url('../fonts/bradesco_sans/BradescoSans-MediumItalic-web.ttf') format('truetype'),
         url('../fonts/bradesco_sans/BradescoSans-MediumItalic.svg#bradescosans-mediumitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Bradesco Sans Regular */
@font-face {
    font-family: 'bradescosans-regular';
    src: url('../fonts/bradesco_sans/BradescoSans-Regular.eot');
    src: url('../fonts/bradesco_sans/BradescoSans-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bradesco_sans/BradescoSans-Regular.woff') format('woff'),
         url('../fonts/bradesco_sans/BradescoSans-Regular-web.ttf') format('truetype'),
         url('../fonts/bradesco_sans/BradescoSans-Regular.svg#bradescosans-regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Bradesco Sans Regular Italic */
@font-face {
    font-family: 'bradescosans-regularitalic';
    src: url('../fonts/bradesco_sans/BradescoSans-RegularItalic.eot');
    src: url('../fonts/bradesco_sans/BradescoSans-RegularItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bradesco_sans/BradescoSans-RegularItalic.woff') format('woff'),
         url('../fonts/bradesco_sans/BradescoSans-RegularItalic-web.ttf') format('truetype'),
         url('../fonts/bradesco_sans/BradescoSans-RegularItalic.svg#bradescosans-regularitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Bradesco Sans Semi Bold */
@font-face {
    font-family: 'bradescosans-semibold';
    src: url('../fonts/bradesco_sans/BradescoSans-SemiBold.eot');
    src: url('../fonts/bradesco_sans/BradescoSans-SemiBold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bradesco_sans/BradescoSans-SemiBold.woff') format('woff'),
         url('../fonts/bradesco_sans/BradescoSans-SemiBold-web.ttf') format('truetype'),
         url('../fonts/bradesco_sans/BradescoSans-SemiBold.svg#bradescosans-semibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Bradesco Sans Semi Bold Italic */
@font-face {
    font-family: 'bradescosans-semibolditalic';
    src: url('../fonts/bradesco_sans/BradescoSans-SemiBoldItalic.eot');
    src: url('../fonts/bradesco_sans/BradescoSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bradesco_sans/BradescoSans-SemiBoldItalic.woff') format('woff'),
         url('../fonts/bradesco_sans/BradescoSans-SemiBoldItalic-web.ttf') format('truetype'),
         url('../fonts/bradesco_sans/BradescoSans-SemiBoldItalic.svg#bradescosans-semibolditalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Bradesco Sans Thin */
@font-face {
    font-family: 'bradescosans-thin';
    src: url('../fonts/bradesco_sans/BradescoSans-Thin.eot');
    src: url('../fonts/bradesco_sans/BradescoSans-Thin.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bradesco_sans/BradescoSans-Thin.woff') format('woff'),
         url('../fonts/bradesco_sans/BradescoSans-Thin-web.ttf') format('truetype'),
         url('../fonts/bradesco_sans/BradescoSans-Thin.svg#bradescosans-thin') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Bradesco Sans Thin Italic */
@font-face {
    font-family: 'bradescosans-thinitalic';
    src: url('../fonts/bradesco_sans/BradescoSans-ThinItalic.eot');
    src: url('../fonts/bradesco_sans/BradescoSans-ThinItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bradesco_sans/BradescoSans-ThinItalic.woff') format('woff'),
         url('../fonts/bradesco_sans/BradescoSans-ThinItalic-web.ttf') format('truetype'),
         url('../fonts/bradesco_sans/BradescoSans-ThinItalic.svg#bradescosans-thinitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}


/* Bradesco Sans XBold */
@font-face {
    font-family: 'bradescosans-xbold';
    src: url('../fonts/bradesco_sans/BradescoSans-XBold.eot');
    src: url('../fonts/bradesco_sans/BradescoSans-XBold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bradesco_sans/BradescoSans-XBold.woff') format('woff'),
         url('../fonts/bradesco_sans/BradescoSans-XBold-web.ttf') format('truetype'),
         url('../fonts/bradesco_sans/BradescoSans-XBold.svg#bradescosans-xbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Bradesco Sans XBold Italic */
@font-face {
    font-family: 'bradescosans-xbolditalic';
    src: url('../fonts/bradesco_sans/BradescoSans-XBoldItalic.eot');
    src: url('../fonts/bradesco_sans/BradescoSans-XBoldItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bradesco_sans/BradescoSans-XBoldItalic.woff') format('woff'),
         url('../fonts/bradesco_sans/BradescoSans-XBoldItalic-web.ttf') format('truetype'),
         url('../fonts/bradesco_sans/BradescoSans-XBoldItalic.svg#bradescosans-xbolditalic') format('svg');
    font-weight: normal;
    font-style: normal;
}


/*  Fonte de icones
    ========================================================================== */

    @font-face {
        font-family: 'icones-clubevantagens';
        src: url('../fonts/icones-clubevantagens.eot');
        src: url('../fonts/icones-clubevantagens.eot?#iefix') format('embedded-opentype'),
             url('../fonts/icones-clubevantagens.woff') format('woff'),
             url('../fonts/icones-clubevantagens.ttf')  format('truetype'),
             url('../fonts/icones-clubevantagens.svg#icones-clubevantagens') format('svg');
        font-weight: normal;
    }
    
    .icones {
        font-family: 'icones-clubevantagens' !important;
        font-size: inherit;
        font-style: normal !important;
        font-weight: normal !important;
        color: inherit;
        font-display: swap;
    }
    
    .icone-papelaria { &::before { content: '\e900'; } }   
    .icone-flores-presentes { &::before { content: '\e901'; } }   
    .icone-moda-acessorios { &::before { content: '\e902'; } }   
    .icone-loja-online { &::before { content: '\e903'; } }   
    .icone-instagram { &::before { content: '\e904'; } }   
    .icone-chevron-right { &::before { content: '\e905'; } }   
    .icone-times { &::before { content: '\e906'; } }   
    .icone-linkedin { &::before { content: '\e907'; } }   
    .icone-youtube { &::before { content: '\e908'; } }   
    .icone-twitter { &::before { content: '\e909'; } }   
    .icone-facebook { &::before { content: '\e90a'; } }   
    .icone-protecao-familia { &::before { content: '\e90b'; } }   
    .icone-seta-esquerda { &::before { content: '\e90c'; } }   
    .icone-loja-fisica { &::before { content: '\e912'; } }   
    .icone-usuario { &::before { content: '\e913'; } }   
    .icone-chevron-down { &::before { content: '\e914'; } }   
    .icone-pet { &::before { content: '\e915'; } }   
    .icone-infantil { &::before { content: '\e916'; } }   
    .icone-gastronomia { &::before { content: '\e917'; } }   
    .icone-lazer-cultura { &::before { content: '\e918'; } }   
    .icone-educacao { &::before { content: '\e919'; } }   
    .icone-servicos-conveniencia { &::before { content: '\e91a'; } }   
    .icone-viagem-turismo { &::before { content: '\e91b'; } }   
    .icone-eletronicos { &::before { content: '\e91c'; } }   
    .icone-casa-decoracao { &::before { content: '\e91d'; } }   
    .icone-automotivo { &::before { content: '\e91e'; } }   
    .icone-saude-bem-estar { &::before { content: '\e91f'; } }   
    .icone-farmacia { &::before { content: '\e920'; } }   
    .icone-cashback { &::before { content: '\e921'; } }   
    .icone-lupa { &::before { content: '\e922'; } }   
    .icone-pessoa-juridica { &::before { content: '\e90d'; } }   
    .icone-pessoa-fisica { &::before { content: '\e90e'; } }   
    .icone-calendario { &::before { content: '\e90f'; } }   
    .icone-compartilhar {&::before {content: "\e910"; } }
    .icone-coracao { &::before { content: "\e911"; } }
    .icone-email { &::before { content: "\e923"; } }
    .icone-coracao-fav { &::before { content: "\e924"; } }
    .icone-central { &::before { content: "\e925"; } }
    .icone-chevron-left-md { &::before { content: "\e926"; } }
    .icone-chevron-right-md { &::before { content: "\e927"; } }
    .icone-chevron-banner-right { &::before { content: "\e928"; } }
    .icone-pause { &::before { content: "\e929"; } }
    .icone-play { &::before { content: "\e92a"; } }
    .icone-externo { &::before { content: "\e92b"; } }
    .icone-x { &::before { content: "\e92c"; } }