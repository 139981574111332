/* ==========================================================================
   Botoes
   ========================================================================== */
.btn{
   display: inline-flex;
   justify-content: center;
   align-items: center;
   font-weight: 600;
   font-size: 18px;
   line-height: 22px;
    background-color: #aaa;
   color: white;
   padding: 16px 32px;
   border-radius: 10px;
   white-space: nowrap;
   transition: all 0.4s ease;

   &.btn-vermelho{
    background: #CC092F;

    &:hover{
      background-color: darken(#CC092F, 10%);
      color: white;
    }

    &:disabled{
        color: white;
        background: #F30347;
        opacity: 0.4;
    }
   }

   &.btn-azul{
      background: #0061AA;

      &:hover{
        background-color: darken(#0061AA, 10%);
        color: white;
      }
   }

   &.btn-azul-100{
    background: #006CA9;

    &:hover{
      background-color: darken(#006CA9, 10%);
      color: white;
    }
 }

   &.btn-outline{
      background-color: transparent;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      border: 1.5px solid #FFFFFF;
      border-radius: 5px;
      padding: 15px 32px;

      &:hover{
         color: white;
         background-color: rgba(255, 255, 255, 0.2);
       }
   }

   &.btn-outline-vermelho{
      background-color: transparent;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      border: 1.5px solid #CC092F;
      color: #CC092F;
      border-radius: 5px;
      padding: 15px 32px;

      &:hover{
         color: white;
         background-color: #CC092F;
       }
   }


   &.btn-outline-azul-100{
    background-color: transparent;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border: 1.5px solid #006CA9;
    color: #006CA9;
    border-radius: 5px;
    padding: 15px 32px;

    &:hover{
       color: white;
       background-color: #006CA9;
     }
 }

   &.btn-full{
      width: 100% !important;
   }

   &.login{
        height: 48px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        gap: 8px;
       

        @media(max-width: 992px){
            padding: 12px 16px;
            font-size: 14px;
            gap:  10px;
        }
   }
}

.btn-resgatar-mobile{
   position: relative;
   padding: 0;
   height: 56px;
   border: none;
   box-shadow: none;
   border-radius: 30px !important;
   font-size: 16px !important;
   text-transform: uppercase;
   transition: all ease 0.4s;
   background-color: transparent;
   color: #6D6E71;
   border: 2px solid #88898C;
   outline: none;

   &:hover, &:focus {
      background-color: transparent;
      border: 2px solid #88898C;
      color: #6D6E71;
   }

   .icone{
       position: absolute;
       top: 50%;
       left: 8px;
       z-index: 2;
       width: 45px;
       height: 45px;
       background-color: #CC092F;
       border-radius: 50%;
       transform: translateY(-50%);

       &::before,
       &::after{
           content: '';
           position: absolute;
           left: 50%;
           display: block;
           width: 8px;
           height: 2px;
           background-color: white;
           border-radius: 10px;
           transition: all ease 0.4s;
       }

       &::before{
           top: 43%;
           transform: translateX(-50%) rotate(45deg);
       }

       &::after{
           top: 53%;
           transform: translateX(-50%) rotate(-45deg);
       }
   }

   &.active{
       background-color: #D90037;
       border-color: #D90037;
       color: white;

       .icone{
            background-color: white;
           left: 82% !important;

           &::before,
           &::after{
               background-color: #D90037;
           }

           &::before{
               top: 51%;
               transform: translateX(-95%) rotate(45deg);
           }

           &::after{
               top: 47%;
               width: 12px;
               transform: translateX(-26%) rotate(-45deg);
           }
       }
   }
}

.btn-central{
    cursor: pointer;
    margin-bottom: 0;

    &.acessado{
        cursor: initial;
        background: #34B3E7;

        &:hover,
        &:focus{
            background: #34B3E7;
        }
    }
}

.btn-pix {
    background: #29BBAC;
  
    &:hover{
      background-color: darken(#29BBAC, 10%);
      color: white;
    }
}

.btn-desabilitado {
    background-color: #BCBCBE !important;
    color: white;
    pointer-events: none;
}